<template>
  <div id="specialProcesses">
    <el-dialog
      :title="specialProcessesFormTitle"
      width="680px"
      :visible.sync="specialProcessesDialogVisible"
      :close-on-click-modal="false"
      @close="specialProcessesDialogClose"
    >
      <el-form
        ref="specialProcessesFormRef"
        :model="specialProcessesForm"
        :rules="specialProcessesFormRules"
        label-position="right"
        label-width="120px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="项目名称" prop="projectName">
              <el-input v-model="specialProcessesForm.projectName" placeholder="请输入项目名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="工艺流程名称" prop="processName">
              <el-input v-model="specialProcessesForm.processName" placeholder="请输入工艺流程名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="类型" prop="type">
              <el-radio-group v-model="specialProcessesForm.type">
                <el-radio :label="1">
                  关键工序
                </el-radio>
                <el-radio :label="2">
                  特殊工序
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input v-model="specialProcessesForm.remarks" placeholder="请输入备注" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="编制" prop="editor">
              <el-input v-model="specialProcessesForm.editor" placeholder="请输入编制" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="日期" prop="editDate">
              <el-date-picker v-model="specialProcessesForm.editDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="审核" prop="reviewer">
              <el-input v-model="specialProcessesForm.reviewer" placeholder="请输入审核" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="日期" prop="reviewDate">
              <el-date-picker v-model="specialProcessesForm.reviewDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批准" prop="approver">
              <el-input v-model="specialProcessesForm.approver" placeholder="请输入批准" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="日期" prop="approveDate">
              <el-date-picker v-model="specialProcessesForm.approveDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="specialProcessesDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="specialProcessesFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="工艺流程名称">
        <el-input v-model="searchForm.processName" placeholder="请输入工艺流程名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="specialProcessesPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="projectName" label="项目名称" />
      <el-table-column prop="processName" label="工艺流程名称" />
      <el-table-column label="类型">
        <template slot-scope="scope">
          <span v-if="scope.row.type === 1">关键工序</span>
          <span v-if="scope.row.type === 2">特殊工序</span>
        </template>
      </el-table-column>
      <el-table-column prop="remarks" label="备注" />
      <el-table-column prop="editor" label="编制" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.editDate">{{ scope.row.editDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="reviewer" label="审核" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewDate">{{ scope.row.reviewDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="approver" label="批准" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.approveDate">{{ scope.row.approveDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="specialProcessesPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addSpecialProcesses, deleteSpecialProcesses, updateSpecialProcesses, selectSpecialProcessesInfo, selectSpecialProcessesList } from '@/api/universal/specialProcesses'

export default {
  data () {
    return {
      specialProcessesDialogVisible: false,
      specialProcessesFormTitle: '',
      specialProcessesForm: {
        id: '',
        projectName: '',
        processName: '',
        type: '',
        remarks: '',
        editor: '',
        editDate: '',
        reviewer: '',
        reviewDate: '',
        approver: '',
        approveDate: ''
      },
      specialProcessesFormRules: {
        processName: [{ required: true, message: '工艺流程名称不能为空', trigger: ['blur', 'change']}]
      },
      specialProcessesPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        processName: ''
      }
    }
  },
  created () {
    selectSpecialProcessesList(this.searchForm).then(res => {
      this.specialProcessesPage = res
    })
  },
  methods: {
    specialProcessesDialogClose () {
      this.$refs.specialProcessesFormRef.resetFields()
    },
    specialProcessesFormSubmit () {
      if (this.specialProcessesFormTitle === '关键工序和特殊过程清单详情') {
        this.specialProcessesDialogVisible = false
        return
      }
      this.$refs.specialProcessesFormRef.validate(async valid => {
        if (valid) {
          if (this.specialProcessesFormTitle === '新增关键工序和特殊过程清单') {
            await addSpecialProcesses(this.specialProcessesForm)
          } else if (this.specialProcessesFormTitle === '修改关键工序和特殊过程清单') {
            await updateSpecialProcesses(this.specialProcessesForm)
          }
          this.specialProcessesPage = await selectSpecialProcessesList(this.searchForm)
          this.specialProcessesDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.specialProcessesFormTitle = '新增关键工序和特殊过程清单'
      this.specialProcessesDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteSpecialProcesses(row.id)
        if (this.specialProcessesPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.specialProcessesPage = await selectSpecialProcessesList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.specialProcessesFormTitle = '修改关键工序和特殊过程清单'
      this.specialProcessesDialogVisible = true
      this.selectSpecialProcessesInfoById(row.id)
    },
    handleInfo (index, row) {
      this.specialProcessesFormTitle = '关键工序和特殊过程清单详情'
      this.specialProcessesDialogVisible = true
      this.selectSpecialProcessesInfoById(row.id)
    },
    selectSpecialProcessesInfoById (id) {
      selectSpecialProcessesInfo(id).then(res => {
        this.specialProcessesForm.id = res.id
        this.specialProcessesForm.projectName = res.projectName
        this.specialProcessesForm.processName = res.processName
        this.specialProcessesForm.type = res.type
        this.specialProcessesForm.remarks = res.remarks
        this.specialProcessesForm.editor = res.editor
        this.specialProcessesForm.editDate = res.editDate
        this.specialProcessesForm.reviewer = res.reviewer
        this.specialProcessesForm.reviewDate = res.reviewDate
        this.specialProcessesForm.approver = res.approver
        this.specialProcessesForm.approveDate = res.approveDate
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectSpecialProcessesList(this.searchForm).then(res => {
        this.specialProcessesPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectSpecialProcessesList(this.searchForm).then(res => {
        this.specialProcessesPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectSpecialProcessesList(this.searchForm).then(res => {
        this.specialProcessesPage = res
      })
    }
  }
}
</script>

<style>
</style>
